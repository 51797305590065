import React, { useState } from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Contact, Popup } from "../../components/popup";

const Services = () => {
  const [popupShow, setPopupShow] = useState(false);

  const handlePopupModal = () => {
    setPopupShow(!popupShow);
  };
  return (
    <Layout>
      <section className="ourServicePage">
        <div className="container-fluid">
          <div className="innerPageNavigation">
            <ul className="breadcrumbs">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/services/">Services</Link>
              </li>
            </ul>
          </div>
          <div className="wrapperService">
            <h1>
              <span className="circle"></span>Our services
            </h1>

            <div className="servicePage">
              <ul>
                <li>
                  <Link to="/services/enterprise-solutions">
                    <div className="card serviceItem">
                      <div className="card-img">
                        <StaticImage placeholder="none"
                          className="cardgradient"
                          alt="service"
                          src="../../images/servicepage/servicepage-1.svg"
                        />
                        <StaticImage placeholder="none"
                          className="mobile-cardgradient"
                          alt="solutions"
                          src="../../images/ourservice/mobile-enterprise-solutions.svg"
                        />
                      </div>

                      <div className="card-body serviceBody">
                        <div className="card-top">
                          <span className="serviceCount">01</span>
                          <div className="viewMore">
                            View more{" "}
                            <span className="circle">
                              <StaticImage placeholder="none"
                                alt="service"
                                src="../../images/arrow.svg"
                              />
                            </span>
                          </div>
                        </div>
                        <div className="card-bottom">
                          <h2>Enterprise Solutions</h2>
                          <ul className="newsCaption-link">
                            <li>Enterprise application development</li>
                            <li>Microservice architecture</li>
                            <li>Enterprise automation</li>
                            <li>Big Data Service & Predictive Analytics</li>
                            <li>Blockchain</li>
                            <li>Ecommerce</li>
                          </ul>
                        </div>
                      </div>
                      <div className="img-bottom">
                        <StaticImage placeholder="none"
                          className="cardgradient"
                          alt="service"
                          src="../../images/servicepage-ellipse.png"
                        />
                        <StaticImage placeholder="none"
                          className="mobile-cardgradient"
                          alt="service"
                          src="../../images/mobile-servicepage-ellipse.png"
                        />
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/services/mobility-solutions">
                    <div className="card serviceItem">
                      <div className="card-img">
                        <StaticImage placeholder="none"
                          className="cardgradient"
                          alt="service"
                          src="../../images/servicepage/servicepage-2.svg"
                        />
                        <StaticImage placeholder="none"
                          className="mobile-cardgradient"
                          alt="solutions"
                          src="../../images/ourservice/mobile-mobility-solutions.svg"
                        />
                      </div>
                      <div className="card-body serviceBody">
                        <div className="card-top">
                          <span className="serviceCount">02</span>
                          <div className="viewMore">
                            View more{" "}
                            <span className="circle">
                              <StaticImage placeholder="none"
                                alt="service"
                                src="../../images/arrow.svg"
                              />
                            </span>
                          </div>
                        </div>
                        <div className="card-bottom">
                          <h2>Mobility Solutions</h2>
                          <ul className="newsCaption-link">
                            <li>iOS</li>
                            <li>Android</li>
                            <li>Cross platform</li>
                            <li>Progressive Web Apps</li>
                          </ul>
                        </div>
                      </div>
                      <div className="img-bottom">
                        <StaticImage placeholder="none"
                          className="cardgradient"
                          alt="service"
                          src="../../images/servicepage-ellipse.png"
                        />
                        <StaticImage placeholder="none"
                          className="mobile-cardgradient"
                          alt="service"
                          src="../../images/mobile-servicepage-ellipse.png"
                        />
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/services/data-solutions">
                    <div className="card serviceItem">
                      <div className="card-img">
                        <StaticImage placeholder="none"
                          className="cardgradient"
                          alt="ellipse"
                          src="../../images/servicepage/servicepage-3.svg"
                        />
                        <StaticImage placeholder="none"
                          className="mobile-cardgradient"
                          alt="solutionsa"
                          src="../../images/ourservice/mobile-data-solutions.svg"
                        />
                      </div>
                      <div className="card-body serviceBody">
                        <div className="card-top">
                          <span className="serviceCount">03</span>
                          <div className="viewMore">
                            View more{" "}
                            <span className="circle">
                              <StaticImage placeholder="none"
                                alt="ellipse"
                                src="../../images/arrow.svg"
                              />
                            </span>
                          </div>
                        </div>
                        <div className="card-bottom">
                          <h2>Data Solutions</h2>
                          <ul className="newsCaption-link">
                            <li>Data science</li>
                            <li>Data engineering</li>
                            <li>Machine learning</li>
                            <li>Artificial intelligence</li>
                          </ul>
                        </div>
                      </div>
                      <div className="img-bottom">
                        <StaticImage placeholder="none"
                          className="cardgradient"
                          alt="service"
                          src="../../images/servicepage-ellipse.png"
                        />
                        <StaticImage placeholder="none"
                          className="mobile-cardgradient"
                          alt="service"
                          src="../../images/mobile-servicepage-ellipse.png"
                        />
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/services/cloud-solutions">
                    <div className="card serviceItem">
                      <div className="card-img">
                        <StaticImage placeholder="none"
                          className="cardgradient"
                          alt="service"
                          src="../../images/servicepage/servicepage-4.svg"
                        />
                        <StaticImage placeholder="none"
                          className="mobile-cardgradient"
                          alt="solutions"
                          src="../../images/ourservice/mobile-cloud-solutions.svg"
                        />
                      </div>
                      <div className="card-body serviceBody">
                        <div className="card-top">
                          <span className="serviceCount">04</span>
                          <div className="viewMore">
                            View more{" "}
                            <span className="circle">
                              <StaticImage placeholder="none"
                                alt="service"
                                src="../../images/arrow.svg"
                              />
                            </span>
                          </div>
                        </div>
                        <div className="card-bottom">
                          <h2>Cloud Solutions</h2>
                          <ul className="newsCaption-link">
                            <li>AWS</li>
                            <li>Devops</li>
                            <li>DevSecOps</li>
                            <li>Cloud Services</li>
                            <li>Cloud monitoring</li>
                            <li>Cloud migration</li>
                          </ul>
                        </div>
                      </div>
                      <div className="img-bottom">
                        <StaticImage placeholder="none"
                          className="cardgradient"
                          alt="service"
                          src="../../images/servicepage-ellipse.png"
                        />
                        <StaticImage placeholder="none"
                          className="mobile-cardgradient"
                          alt="service"
                          src="../../images/mobile-servicepage-ellipse.png"
                        />
                      </div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/services/qa-testing">
                    <div className="card serviceItem">
                      <div className="card-img">
                        <StaticImage placeholder="none"
                          className="cardgradient"
                          alt="service"
                          src="../../images/servicepage/servicepage-5.svg"
                        />
                        <StaticImage placeholder="none"
                          className="mobile-cardgradient"
                          alt="solutions"
                          src="../../images/ourservice/mobile-qa-solutions.svg"
                        />
                      </div>
                      <div className="card-body serviceBody">
                        <div className="card-top">
                          <span className="serviceCount">05</span>
                          <div className="viewMore">
                            View more{" "}
                            <span className="circle">
                              <StaticImage placeholder="none"
                                alt="service"
                                src="../../images/arrow.svg"
                              />
                            </span>
                          </div>
                        </div>
                        <div className="card-bottom">
                          <h2>QA & Testing</h2>
                          <ul className="newsCaption-link">
                            <li>Manual</li>
                            <li>Automation</li>
                          </ul>
                        </div>
                      </div>
                      <div className="img-bottom">
                        <StaticImage placeholder="none"
                          className="cardgradient"
                          alt="service"
                          src="../../images/servicepage-ellipse.png"
                        />
                        <StaticImage placeholder="none"
                          className="mobile-cardgradient"
                          alt="service"
                          src="../../images/mobile-servicepage-ellipse.png"
                        />
                      </div>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="contactSection">
        <div className="container-fluid">
          <div className="wrapperService">
            <div className="contactRelative">
              <div className="contactTitle">
                <div className="casestudySlider">
                  <h1>
                    <span className="circle"></span>Contact us
                  </h1>
                </div>
              </div>
            
                <Contact type="static" handleChange={handlePopupModal} />
            </div>
          </div>
        </div>
      </section>
      <Popup show={popupShow} handleClose={handlePopupModal} body="success" />
    </Layout>
  );
};
export const Head = () => (
  <Seo
    title="Services | Xminds"
    description="We have vast experience in providing top app development services. Xminds deliver the best Mobility solutions, Data solutions, Cloud solutions, and more."
    url="https://www.xminds.com/services"
  />
);


export default Services;
